


































import { Component, Vue, Prop } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import ICrudClient from "@/lib/ICrudClient";
import { Prescreening } from "@/data/models/Questionnaires";
import PrescreeningExtractions from "@/components/extractionForms/PrescreeningExtractionsForm.vue";

@Component({
  components: {
    ModelTable,
    PrescreeningExtractions
  }
})
export default class QuestionnaireTable extends Vue {
  @Prop()
  public provider!: DataProvider<Prescreening>;

  @Prop()
  public crud!: ICrudClient<Prescreening>;

  @Prop()
  public columns!: any[];

  @Prop()
  public addRoute!: (id: string) => Location;

  @Prop()
  public viewRoute!: (id: string) => Location;

  private selected: any[] = [];
  private openDialog = false;

  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    this.$router.push(route(id));
  }
}

































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    FormContainer,
    DatePicker,
  },
})
export default class PrescreeningExtractions extends Vue {
  @Prop()
  public provider!: any;

  private users: any = [];
  private assignedUsers: any = [];
  private actionsTaken: any = [];
  private filters: any = [];

  @Watch("filters", { deep: true })
  onItemChange() {
    if (!this.filters) {
      this.initializeItem();
    }
  }

  async mounted() {
    const allUsers = (await this.$service.providers.extractionUsers.fetchItemsAsync()).items;
    this.users = allUsers.map((i: any) => i.firstName + " " + i.lastName);
    this.assignedUsers = allUsers.map((i: any) => i.firstName + " " + i.lastName);

    const allPrescreeningActions = (await this.$service.providers.prescreeningActionTaken.fetchItemsAsync()).items;
    this.actionsTaken = allPrescreeningActions.map((i: any) => i.value);

    this.initializeItem();
  }

  getDinstinct(arr: any) {
    return Array.from(
      arr.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
    );
  }

  initializeItem() {
    this.filters = {
      users: [],
      createdAt: null,
      assignedTo: [],
      actionTaken: [],
      isEligible: null,
      assignedToProject: null
    }
  }
}
